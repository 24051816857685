<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:41
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2023-01-30 17:17:08
 * @FilePath: \ulove-web\src\views\About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="community">
    <topnavigation />
    <!-- banner -->
    <div class="banner">
    </div>
    <!-- 解决方案文字 -->
    <div class="programme">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        智慧社区解决方案
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <p> 好用点点智慧社区解决方案,面向各类型社区提供与公安内网打通的Saas平台、智慧硬件产品,从治安防控升级、社区管理高效、居住
        体验优化三个维度出发,以一标三实信息采集为基础,融合智慧门禁、智慧道闸、智慧充电桩、智慧梯控等系统,搭建数字看板、数字驾驶
        舱,自动分析辖区风险并提供精准的人口管理、流动人员管理、疫情管控等创新应用,政府、职能单位与小区物业可联动共治,全面提高社
        区智治水平。
      </p>
    </div>
    <!-- 合作案例 -->
    <div class="case">
      <div class="title">合作案例</div>
      <div class="row">
        <div class="item">
          <img src="../assets/sq/center1.png" alt="" />
          <p>杭州滨江同协金座小区</p>
        </div>
        <div class="item">
          <img src="../assets/sq/center2.png" alt="" />
          <p>赣州锦绣新天地</p>
        </div>


      </div>
    </div>
    <!-- 网格 -->
    <div class="wg">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        智慧社区 — 网格化管理
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <div class="sub-title">通过一定标准将社区划分为多个网格，在线上对网格内的"人、地、物、情、事、组织”信息统一管理。</div>
      <div class="row">
        <div class="item">实有房屋管理</div>
        <div class="item">户籍管理</div>
        <div class="item">人口卡片</div>
        <div class="item">小区/村管理</div>
        <div class="item">网格看板</div>
      </div>
      <img class="wg-main" src="../assets/sq/wg-main.png" alt="">
    </div>
    <!-- 服务 -->
    <div class="fw">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        一站式服务百姓民生
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <img class="wg-main" src="../assets/sq/fw-main.png" alt="">
    </div>
    <div class="yj">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        集成智能硬件，流口轨迹全掌控、数据信息全共享
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <img class="wg-main" src="../assets/sq/yj-main.png" alt="">
    </div>
    <!-- 其他 -->
    <div class="other">
      <img src="../assets/sq/other-main.png" alt="">
    </div>
    <bottombanner />
  </div>
</template>
<script>
import Bottombanner from "../components/bottombanner.vue";
import Topnavigation from "../components/topnavigation.vue";
export default {
  components: {
    Bottombanner,
    Topnavigation
  },
  data() {
    return {
    }
  },

  mounted() {

  },
  methods: {


  }
}
</script>
<style lang="less" scoped>
.community {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-r {
    transform: rotate(180deg);
  }
}

.banner {
  background-image: url(../assets/sq/banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-size: 50px;
  font-family: OPPOSans;
  font-weight: 800;
  color: #FFFFFF;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding-left: 207px;
  box-sizing: border-box;
  justify-content: center;

  p {
    line-height: 80px;
    margin: 0;

  }
}

.programme {
  width: 1601px;
  height: 294px;
  background-image: url(../assets/sq/programme-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 120px;
  margin-bottom: 96px;
  padding: 52px 70px 0 60px;
  box-sizing: border-box;

  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 38px;
      height: 36px;
      margin: 0 30px;
    }


  }

  p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 31px;
    text-indent: 2em;
    text-align: left;
  }
}

.case {
  text-align: left;
  width: 1601px;

  .title {
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #222222;
    margin-bottom: 40px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .row img {
    width: 770px;
    height: 380px;
  }

  .row .item {
    position: relative;
  }

  .row p {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
    position: absolute;
    width: 770px;
    display: inline-block;
    bottom: 40px;
    left: 66px;
  }
}

.wg {
  padding-top: 120px;
  background-image: url(../assets/sq/wg-bg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;

  .title {
    font-size: 40px;
    padding-top: 78px;

    img {
      margin: 0 30px;
    }
  }

  .sub-title {
    font-size: 24px;
    margin-top: 30px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 68px;

    .item {
      width: 254px;
      height: 70px;
      border: 1px solid #0074F4;
      background: linear-gradient(76deg, #ECF4FF 0%, #D1E4FF 100%);
      border-radius: 10px;
      box-sizing: border-box;
      margin-right: 82px;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item:last-child {
      margin-right: 0;
    }
  }

  .wg-main {
    width: 1488px;
    height: 629px;
    margin-top: 80px;
  }
}

.fw {
  background-image: url(../assets/sq/wg-bg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  .title {
    font-size: 40px;
    padding-top: 118px;
    img {
      margin: 0 30px;
    }
  }
  .wg-main {
    width: 1599px;
    height: 774px;
    margin-top: 94px;
  }
}

.yj {

  width: 100%;
  height: 1080px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  background-color: #fff;

  .title {
    font-size: 40px;
    padding-top: 90px;

    img {
      margin: 0 30px;
    }
  }

  .wg-main {
    width: 1600px;
    height: 780px;
    margin-top: 70px;
  }
}

.other {
  background-image: url(../assets/sq/other-bg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1602px;
    height: 1000px;
  }
}
</style>
