<template>
  <div class="sociallink">
    <topnavigation />
    <!-- banner -->
    <div class="banner">
    </div>
    <!-- 解决方案文字 -->
    <div class="programme">
      <div class="title">
        <img src="../../assets/hyy/twobule1.png" />
        社会应急联动指令
        <img class="img-r" src="../../assets/hyy/twobule1.png" />
      </div>
      <p> 
          社会应急联动指挥平台是基于智能化+边缘计算能力，整合各种应急救援力量及市政资源，实现多警种、多部门、多层次、跨地域的统一
        接警、统一协调、紧急预案、及时反馈、实时监督的综合性平台，实现横向联动、上下联动、资源联动、社会联动、在线联动的五级联动。
      </p>
    </div>
    <!-- 合作案例 -->
    <div class="case">
      <div class="title">合作案例</div>
      <div class="row">
        
      <div class="swiper-container">
        <div class="swiper-wrapper" style="width: 820px">
          <div class="swiperItem swiper-slide" v-for="(item, index) in listData" :key="index">
            <img :src="item.src" alt="" />
          </div>
        </div>
      </div>
        <!-- <div class="item">
          <img src="../../assets/sq/center1.png" alt="" />
          <p>杭州滨江同协金座小区</p>
        </div>
        <div class="item">
          <img src="../../assets/sq/center2.png" alt="" />
          <p>赣州锦绣新天地</p>
        </div> -->
      </div>
    </div>
    <!-- 特点 -->
    <div class="td">
      <div class="title">
        <img src="../../assets/hyy/twobule1.png" />
        社会应急联动指令特点
        <img class="img-r" src="../../assets/hyy/twobule1.png" />
      </div>
      <div class="top">
        <div class="item" style="width:726px">
          <div> <span class="span-num">01</span>  <span class="span-tit">应急事件智能接报</span> </div>
          <div>
            <p>
              实现应急指令接收、录入、交办、催办、追加单位、全程跟踪等功能， 
              可以实现将市局三台合一、12345等系统属于应急联动范畴的指令实 
              时同步并自动生成联动指令单。针对重大事件，可从预案库中选择预 
              案，应急指令会根据预案自动执行办理过程，实现联动指令一站式派 
              单。
            </p>
          </div>
        </div>
        <div class="item" style="width:671px">
          <div> <span  class="span-num">02</span>  <span  class="span-tit">可视化应急资源</span> </div>
          <div>
            <p>
            通过整合应急管理相关的应急队伍库、应急专家库、应急物资、 
            应急装备库、危险源等信息，通过 GPS 定位，PIS 地图资源， 
            将所有应急资源在地图上实时显示，并可实现应急资源的精确调 
            度，辅助决策。
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="item">
          <div> <span class="span-num">03</span>  <span class="span-tit">智能化预案管理</span> </div>
          <div>
            <p>
              在预案数据、应急历史数据、演练方案数 
              据、典型案例数据等基础上，为用户提供 
              应急预案建模服务、应急预案模板定制服 
              务，以及预案浏览检索、组织、评价与分 
              析服务等。
            </p>
          </div>
        </div>
        <div class="item">
          <div> <span class="span-num">04</span>  <span class="span-tit">多维度事件回溯</span> </div>
          <div>
            <p>
              系统可对应急事件进行全过程回溯，通过 
              时间轴电子地图等方式，全面还原应急事 
              件的发生、处置过程、处置结果，为应急 
              队伍、应急救援工作的开展提供宝贵经验
            </p>
          </div>
        </div>
        <div class="item">
          <div> <span class="span-num">05</span>  <span class="span-tit">事件处置考核评估</span> </div>
          <div>
            <p>
              系统可自设定各类考核参数，系统将自动 
              计算评估，提供各类抽查考评报表、工作 
              量考评、时效考评、处置质量考评报表等
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 优势 -->
    <div class="ys">
      <div class="title">
        <img src="../../assets/hyy/twobule1.png" />
        社会联动应急指令优势
        <img class="img-r" src="../../assets/hyy/twobule1.png" />
      </div>
      <div class="list">
        <div class="item" v-for="item in yslist" :key="item.name">
          <div>
            <img :src="item.url" alt="">
          </div>
          <div class='line'>

          </div>
          <div class="name">{{item.name}}</div>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
    <!-- 效果 -->
    <div class="xg">
      <div class="title">
        <img src="../../assets/hyy/twobule1.png" />
        社会应急联动指令效果图
        <img class="img-r" src="../../assets/hyy/twobule1.png" />
      </div>
      <div class="png">

      </div>
    </div>
    <bottombanner />
  </div>
</template>

<script>
import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
import Bottombanner from "../../components/bottombanner.vue";
import Topnavigation from "../../components/topnavigation.vue";
export default {
  components:{
    Bottombanner,
    Topnavigation
  },
  data(){
    return{
      yslist:[
        {
          name:'优势一',
          text:'统一平台，协同作战',
          url: require('../../assets/sociallink/ys1.png') 
        },
        {
          name:'优势二',
          text:'可视化指挥调度',
          url: require('../../assets/sociallink/ys2.png')
        },
        {
          name:'优势三',
          text:'应急全流程管理',
          url: require('../../assets/sociallink/ys3.png')
        },
        {
          name:'优势四',
          text:'快速响应，决策得力',
          url: require('../../assets/sociallink/ys4.png')
        },
      ],
      listData: [
        { src:require("../../assets/sociallink/list1.png") },
        { src:require("../../assets/sociallink/list2.png") },
        { src:require( "../../assets/sociallink/list3.png") },
        { src:require( "../../assets/sociallink/list4.png") },
        { src:require( "../../assets/sociallink/list5.png") },
        { src:require( "../../assets/sociallink/list6.png") },
        { src:require( "../../assets/sociallink/list7.png") },
        { src:require( "../../assets/sociallink/list8.png") },
        { src:require( "../../assets/sociallink/list9.png") },
        { src:require( "../../assets/sociallink/list10.png") },
        { src:require( "../../assets/sociallink/list11.png") },
        { src:require( "../../assets/sociallink/list12.png") },
        { src:require( "../../assets/sociallink/list13.png") },
        { src:require( "../../assets/sociallink/list14.png") },
      ],
      
    }
  },
  mounted() {
    new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      //mousewheel: true, //滚轮
      autoplay: {
        //自动开始
        delay: 500, //时间间隔
        action: true, //*手动操作轮播图后不会暂停*
        disableOnInteraction: false,
      },
      slidesPerView: 2,
      centeredSlides: false,
      loop:true, // 循环模式选项
    });
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
.sociallink {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-r {
    transform: rotate(180deg);
  }
}
.banner {
  background-image: url(../../assets/sociallink/banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-size: 50px;
  font-family: OPPOSans;
  font-weight: 800;
  color: #FFFFFF;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding-left: 207px;
  box-sizing: border-box;
  justify-content: center;

  p {
    line-height: 80px;
    margin: 0;

  }
}
.programme {
  width: 1601px;
  height: 294px;
  background-image: url(../../assets/sq/programme-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 120px;
  margin-bottom: 96px;
  padding: 52px 70px 0 60px;
  box-sizing: border-box;

  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 38px;
      height: 36px;
      margin: 0 30px;
    }


  }

  p {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 31px;
    text-indent: 2em;
    text-align: left;
  }
}
.case {
  text-align: left;
  width: 1601px;

  .title {
    text-align: left;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #222222;
    margin-bottom: 40px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .swiper-container {
      width: 1600px;
      height: 380px;
      margin-top: 39px;
    }
    .swiper-slide {
      width: 770px !important;
      height: 380px;
      border-radius: 30px;
      background-color: #fff;
      margin-right: 30px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  // .row img {
  //   width: 770px;
  //   height: 380px;
  // }

  // .row .item {
  //   position: relative;
  // }

  // .row p {
  //   font-size: 32px;
  //   font-family: PingFang SC;
  //   font-weight: bold;
  //   color: #FFFFFF;
  //   line-height: 30px;
  //   position: absolute;
  //   width: 770px;
  //   display: inline-block;
  //   bottom: 40px;
  //   left: 66px;
  // }
}
.td {
  margin-top: 120px;
  background-image: url(../../assets/sociallink/td-b.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  .title {
    font-size: 40px;
    padding-top: 78px;
    img {
      margin: 0 30px;
    }
  }
  .top{
    width: 1600px;
    margin: 93px auto 39px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .item{
      height: 218px;
      background:#fff;
      border-radius: 10px;
      padding: 60px 41px 52px; 
      .span-num{
        font-size: 34px;
        font-family: OPPOSans;
        font-weight: 800;
        color: #333333;
      }
      .span-tit{
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: left;
      }
    }
  }
  .bottom{
    width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .item{
      padding: 50px 42px;
      width: 423px;
      height: 237px;
      background: #fff;
      border-radius: 10px;
      .span-num{
        font-size: 34px;
        font-family: OPPOSans;
        font-weight: 800;
        color: #333333;
      }
      .span-tit{
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      p{
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: left;
      }
    }
  }


}
.ys{
  margin-top: 120px;
  background-image: url(../../assets/sociallink/ys.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  .title {
    font-size: 40px;
    padding-top: 78px;
    img {
      margin: 0 30px;
    }
  }
  .list{
    width: 1364px;
    height: 533px;
    margin: 156px auto 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    .item{
      padding: 105px 30px 0;
      width: 260px;
      height: 488px;
      background: #FFFFFF;
      border-radius: 20px;
      img{
        width: 81px;
        height: 81px;
      }
      .line{
        height: 1px;
        width: 100%;
        background:#DDDDDD;
        margin: 60px  0 80px;
      }
      .name{
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #222222;
        margin-bottom: 25px;
      }
      .text{
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #222222;
      }
    }
  }
}
.xg{
  width: 100%;
  height: 1080px;
  background: #FFFFFF;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  .title {
    font-size: 32px;
    padding-top: 78px;
    img {
      margin: 0 30px;
    }
  }
  .png{
    width: 1598px;
    height: 853px;
    background-image: url(../../assets/sociallink/xg.png);
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 52px auto 0;
  }
}

</style>