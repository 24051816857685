<template>
  <div class="hyy">
    <topnavigation class="header"></topnavigation>
    <!-- <router-view></router-view> -->
    <div class="content">
      <div class="content-top">
        <div class="first"></div>
        <div class="probation" @click="probation">
          好用云免费试用一年 | 立即试用
        </div>
      </div>
      <div class="sencend">
        <div class="saastext">
          <div class="caput">
            <div class="img-1"></div>
            <div class="caput-size">好用云SaaS平台</div>
            <div class="img-2"></div>
          </div>
          <div class="saastext-size">
            好用云基于“社区、园区”场景SaaS产品，平台集成如“门禁、道闸、梯控、充电桩”等各类硬件聚集“社区、园区”周边各种服务资源，优化业主生活体验，降低物业服务成本。面向“政府机构、物业公司、住户业主”提供安全、便捷、健康的人员治理、智慧生活等服务。
          </div>
        </div>
        <div class="bottom-box">
          <div class="bottomleft-box">
            <div class="left-item" v-for="item in sencendList" :key="item.name">
              <div class="title">{{ item.name }}</div>
              <div class="png-list">
                <div class="png-item" v-for="sonitem in item.son" :key="sonitem.imgname">
                  <img :src="sonitem.url" alt="" />
                  <p>{{ sonitem.imgnaem }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomright-box"></div>
        </div>
      </div>
      <div class="third">
        <div class="midbox">
          <div class="box-item" v-for="(item, index) in thirdList" :key="index">
            <div class="title">
              <span style="
                  font-size: 50px;
                  font-family: Helvetica;
                  font-weight: bold;
                ">{{ item.num }}</span>
              <span style="
                  font-size: 40px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  margin-left: 30px;
                ">{{ item.name }}</span>
            </div>
            <div class="sen-title">{{ item.title }}</div>
            <div class="line"></div>
            <div class="text">
              <span class="word0">{{ item.text0 }}</span>
              <span class="word">{{ item.text }}</span>
              <span class="word1">{{ item.text1 }}</span>
              <span class="word2">{{ item.text2 }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="fourth">
        <div class="content">
          <div class="left">
            <div class="left-item" v-for="item in fourthList" :key="item.title">
              <img :src="item.url" alt="" />
              <div class="text-box">
                <p class="title">{{ item.title }}</p>
                <p class="word">{{ item.text }}</p>
              </div>
            </div>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="fivth">
        <div class="top">
          <div class="title">
            <img src="../../assets/hyy/twobule1.png" alt="" />
            <div style="padding: 0 10px">好用云的五大优势</div>
            <img src="../../assets/hyy/twobule2.png" alt="" />
          </div>
        </div>
        <div class="bottom">
          <div class="midbox">
            <div class="midbox-item" v-for="item in fivmidList" :key="item.title">
              <div class="title">{{ item.title }}</div>
              <div class="buleline"></div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
          <div class="title-text">
            <img src="../../assets/hyy/whitetwo2.png" alt="" />
            <div style="padding: 0 20px">我们提供服务保障</div>
            <img src="../../assets/hyy/whitetwo1.png" alt="" />
          </div>
          <div class="white-list">
            <div class="whitelist-item" v-for="item in fivwhiteList" :key="item.num">
              <div>
                <span style="
                    font-size: 40px;
                    font-family: Helvetica;
                    font-weight: bold;
                  ">{{ item.num }}</span>
                <span style="
                    font-size: 32px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    margin-left: 20px;
                  ">{{ item.title }}</span>
              </div>
              <div class="word">
                {{ item.word }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sixth">
        <div class="content"></div>
      </div>
    </div>
    <bottombanner></bottombanner>
    <div v-if="visble" class="overspread">
      <div class="pop-up">
        <div class="lateron" @click="probation">稍后试用</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Access from "./Access.vue";
import topnavigation from "../../components/topnavigation.vue";
import bottombanner from "../../components/bottombanner.vue";
// import Ladder from "./Ladder .vue";
export default {
  components: { topnavigation, bottombanner },

  data() {
    return {
      visble: false,
      sencendList: [
        {
          name: "物业管理PC端",
          son: [
            {
              imgnaem: "社区管理",
              url: require("../../assets/hyy/sqgl.png"),
            },
            {
              imgnaem: "社区服务",
              url: require("../../assets/hyy/sqfw.png"),
            },
            {
              imgnaem: "财务管理",
              url: require("../../assets/hyy/cwgl.png"),
            },
            {
              imgnaem: "社区运营",
              url: require("../../assets/hyy/sqyy.png"),
            },
            {
              imgnaem: "硬件管理",
              url: require("../../assets/hyy/yjgl.png"),
            },
          ],
        },
        {
          name: "业主微信小程序端",
          son: [
            {
              imgnaem: "线上缴费",
              url: require("../../assets/hyy/xsjf.png"),
            },
            {
              imgnaem: "线上报修",
              url: require("../../assets/hyy/xsbx.png"),
            },
            {
              imgnaem: "消息公告栏",
              url: require("../../assets/hyy/xxgg.png"),
            },
            {
              imgnaem: "房屋租售",
              url: require("../../assets/hyy/fwzs.png"),
            },
            {
              imgnaem: "二手置换",
              url: require("../../assets/hyy/eszh.png"),
            },
          ],
        },
      ],
      thirdList: [
        {
          num: "01",
          name: "社区管理",
          title: "人、车、房精准管理，安防升级",
          text: "业主、租客、访客等不同角色人员管理，与公安内网实名信息比对，通行管理；车辆管理；住宅楼栋管理，",
          text1: "降低治安风险",
        },
        {
          num: "02",
          name: "社区服务",
          title: "工单报修、服务在线",
          text: "报修、建议、投诉等，业主可通过小程序线上即拍即反馈，",
          text1: "物业会在第一时间跟进处理",
          text2: "，提高业主反馈问题，物业解决问题的效率，拉近业主和物业距离。",
        },
        {
          num: "03",
          name: "社区运营",
          title: "通知公告、增值创收",
          text: "通知公告线上编辑发布；充电桩、停车场运营；线上广告位租赁、房屋代售等",
          text1: "为物业增 值创收",
        },
        {
          num: "04",
          name: "财务管理",
          title: "灵活缴费、一键催缴",
          text0: "7×24小时线上缴费",
          text: "，业户自行完成查费缴费，全面解放物业收款员；自定义缴费模式，平台自动生成账单周期，",
          text1: "一键微信/短信催缴",
        },
      ],
      fourthList: [
        {
          url: require("../../assets/hyy/zncs.png"),
          title: "智能催收",
          text: "一键发送缴费通知单，短信/微信服务通知 提示业主查看账单并线上缴费。",
        },
        {
          url: require("../../assets/hyy/7x24.png"),
          title: "7*24h在线缴费",
          text: "7*24小时在线缴费物业管理员无需跑腿， 业主随时随地轻松缴费。",
        },
        {
          url: require("../../assets/hyy/yjcz.png"),
          title: "一键出账",
          text: "财务报表自动生成，一键生成费用即可高 效完成出账工作。",
        },
        {
          url: require("../../assets/hyy/znxz.png"),
          title: "智能销单",
          text: "业主自助缴费后，系统自动销单，不需财 务人工操作。",
        },
        {
          url: require("../../assets/hyy/znzz.png"),
          title: "智能做账",
          text: "系统自动生成财务报表，减少财务工作量， 账单永久保存，同时不会影响电脑运行。",
        },
      ],
      fivmidList: [
        {
          title: "多方数据统一",
          text: "业主，租客，家属，车辆，房屋，访客",
        },
        {
          title: "软硬件资源共享",
          text: "智慧通行，多码合一，智慧安防…",
        },
        {
          title: "风险分析研判",
          text: "进出人员风险自动分析研判，数据更鲜活，居住更安心",
        },
        {
          title: "实现减岗增效",
          text: "一套智慧平台，人员、车辆信息最多只需录入一次，免去重复繁琐的登记工作，实现减岗增效",
        },
        {
          title: "界面清晰易用",
          text: "人员管理，财务管理，车辆管理，报修管理…",
        },
      ],
      fivwhiteList: [
        {
          num: "01",
          title: "提供免费视频学习功能",
          word: "组织资深技术人员录制各个关键功能和应用方法的培训视频，用户可以随时免费学习。",
        },
        {
          num: "02",
          title: "组织网络培训",
          word: "定期组织由资深技术人员主讲的网络培训，所有用户可 以免费报名参加。",
        },
        {
          num: "03",
          title: "“面对面”培训及技术支持",
          word: "用户可以自愿付费购买极致公司的现场技术支持服务， 享受“面对面”的培训和技术支持。",
        },
      ],
    };
  },

  mounted() { },

  methods: {
    probation() {
      this.visble = !this.visble;
    },
  },
};
</script>

<style lang="less" scoped>
.hyy {
  width: 100%;

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .content {
    width: 100%;

    .first {
      width: 100%;
      height: 1080px;
      // background: url('../assets/home-second.png') no-repeat 100% 100%;
      background: url("../../assets/hyy/banner.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    .sencend {
      overflow: hidden;
      width: 100%;
      height: 1080px;
      // background: url('../assets/home-second.png') no-repeat 100% 100%;
      background: url("../../assets/hyy/pgd2.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .saastext {
        width: 1598px;
        height: 215px;
        // margin: 77px auto;
        margin: 77px auto 77px;
        border-radius: 20px;
        background: url("../../assets/hyy/saastext.png") no-repeat;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        color: #333;
        text-align: center;
      }

      .bottom-box {
        width: 83%;
        //1594
        height: 715px;
        margin: 0 auto;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .bottom-box .bottomleft-box {
        width: 46%;
        height: 715px;
      }

      .bottomleft-box .left-item {
        width: 80%;
        height: 50%;
      }

      .left-item .title {
        text-align: left;
        font-size: 40px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #333333;
        padding-bottom: 15px;
        border-bottom: solid 2px #0063be;
      }

      .left-item .png-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      .png-list .png-item {
        padding-top: 30px;
        width: 33%;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;

        p {
          line-height: 40px;
          margin: 0;
          margin-left: 20px;
          font-size: 18px;
        }
      }

      .bottom-box .bottomright-box {
        width: 53%;
        height: 715px;
        background: url("../../assets/hyy/pc.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .third {
      overflow: hidden;
      width: 100%;
      height: 1080px;
      // background: url('../assets/home-second.png') no-repeat 100% 100%;
      background: url("../../assets/hyy/third.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .midbox {
        width: 83%;
        margin: 206px auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .midbox .box-item {
        background: #edf4fe;
        border-radius: 20px;
        // width: 48%;
        height: 300px;
        margin-bottom: 30px;
        box-sizing: border-box;
        padding-left: 3%;
        width: 770px;
      }

      .midbox .box-item:hover {
        width: 770px;
        height: 300px;
        background: url("../../assets/hyy/itemactive.png") no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        margin-bottom: 30px;
        box-sizing: border-box;
        padding-left: 3%;
        color: #fff;

        .line {
          background: #fff;
        }

        .sen-title {
          color: #fff117;
        }

        .word0 {
          color: #fff117;
        }

        .word1 {
          color: #fff117;
        }
      }

      .box-item .title {
        margin-top: 40px;
        text-align: left;
        // margin-left:8% ;
      }

      .box-item .sen-title {
        margin-top: 5px;
        text-align: left;
        // margin-left:8%;
        color: #165dff;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: bold;
      }

      .box-item .line {
        height: 4px;
        margin-top: 5px;
        width: 8%;
        // margin-left:8%;
        background: #333;
        border-radius: 2px;
      }

      .box-item .text {
        text-align: left;
        color: #333;
        font-size: 16px;
        font-family: PingFang SC;
        margin-top: 10px;
        width: 95%;
      }

      .text .word0 {
        color: #165dff;
        font-weight: bold;
        font-size: 24px;
      }

      .text .word {}

      .text .word1 {
        color: #165dff;
        font-weight: bold;
        font-size: 24px;
      }

      .text .word2 {}
    }

    .fourth {
      overflow: hidden;
      width: 100%;
      height: 1080px;
      background: #f9fbff;
      padding-top: 101px;

      .content {
        margin: 0px 10%;
        width: 80%;
        display: flex;

        .left {
          width: 43%;
        }

        .left .left-item {
          width: 100%;
          display: flex;
          margin-bottom: 70px;
        }

        .left-item img {
          height: 121px;
          width: 21%;
          margin-left: 7%;
          margin-right: 4%;
        }

        .left-item .text-box {
          text-align: left;

          p {
            margin: 0;
          }

          .title {
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #222222;
          }

          .word {
            width: 60%;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: 30px;
          }
        }

        .right {
          //870
          height: 1037px;
          background: url("../../assets/hyy/fourthright.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 57%;
          // background: red;
        }
      }
    }

    .fivth {
      overflow: hidden;
      width: 100%;
      height: 1080px;

      .top {
        width: 100%;
        height: 380px;
        background: #fff;
      }

      .top .title {
        display: flex;
        width: 23%;
        height: 38px;
        align-items: center;
        flex-wrap: nowrap;
        margin: 88px auto;

        img {
          width: 8.5%;
          //455
          height: 36px;
        }

        div {
          font-size: 38px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 1;
        }
      }

      .bottom {
        height: 700px;
        width: 100%;
        background: url("../../assets/hyy/fivebottom.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: relative;
      }

      .bottom .midbox {
        position: absolute;
        top: -164px;
        left: 50%;
        transform: translateX(-50%);
        width: 83%;
        height: 315px;
        background: url("../../assets/hyy/fivmid.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 10 !important;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
      }

      .bottom .midbox .midbox-item {
        width: 15%;
        text-align: left;

        .title {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 30px;
          margin-top: 61px;
        }

        .buleline {
          width: 28%;
          height: 6px;
          background: #0074f4;
          border-radius: 3px;
          margin-top: 9px;
          margin-bottom: 26px;
        }

        .text {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
        }
      }

      .bottom .title-text {
        position: absolute;
        top: 164px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        width: 23%;
        height: 38px;
        align-items: center;
        flex-wrap: nowrap;
        margin: 88px auto;

        img {
          width: 38px;
          //455
          height: 36px;
        }

        div {
          font-size: 38px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #fff;
          line-height: 1;
        }
      }

      .bottom .white-list {
        width: 85%;
        height: 231px;
        position: absolute;
        top: 326px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-around;

        .whitelist-item {
          color: #333333;
          // width: 29%;
          margin-right: 20px;
          height: 150px;
          background: #ffffff;
          border-radius: 30px;
          padding: 50px 60px 50px 30px;
          text-align: left;

          .word {
            width: 430px;
            padding-top: 30px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 500;

            line-height: 30px;
          }
        }

        .whitelist-item:last-child {
          margin-right: 0;
        }
      }
    }

    .sixth {
      overflow: hidden;
      width: 100%;
      height: 1080px;
      background: url("../../assets/hyy/sixth.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .content {
        width: 83%;
        height: 880px;
        margin: 100px auto;
        background: url("../../assets/hyy/sixexcel.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

.content-top {
  position: relative;
}

.probation {
  position: absolute;
  bottom: 372px;
  left: 160px;
  width: 486px;
  height: 66px;
  background: #0063be;
  border-radius: 33px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  line-height: 66px;
  cursor: pointer;
}

.overspread {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  display: block;
}

.pop-up {
  position: fixed;
  left: calc(50% - 481px);
  top: calc(50% - 260px);
  z-index: 900;
  width: 962px;
  height: 520px;
  background: url("../../assets/pop-up.png") no-repeat;
  background-size: 100% 100%;

  .lateron {
    position: absolute;
    bottom: 53px;
    right: 71px;
    width: 130px;
    height: 49px;
    border: 1px solid #0060ff;
    border-radius: 24px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #0060ff;
    line-height: 49px;
    cursor: pointer;
  }
}

.caput {
  display: flex;
  justify-content: center;
  padding-top: 41px;
  padding-bottom: 34px;

  .img-1 {
    width: 38px;
    height: 36px;
    margin-right: 31px;
    background: url("../../assets/right-round.png") no-repeat;
    background-size: 100% 100%;
  }

  .caput-size {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }

  .img-2 {
    width: 42px;
    height: 31px;
    background: url("../../assets/left-round.png") no-repeat;
    background-size: 100% 100%;
    margin-left: 31px;
  }
}

.saastext-size {
  width: 1488px;
  height: 56px;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  margin: 0 62px 0 48px;
  text-align: left;
  text-indent: 1em;
}
</style>
