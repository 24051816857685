<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:41
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2023-01-30 15:50:36
 * @FilePath: \ulove-web\src\views\About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="about">
    <topnavigation />
    <div class="banner" id="top">
      <!-- <p> 11年深耕于政府信息化建设的高新</p>
      <p> 技术企业，为政府机构提供智能完</p>
      <p> 善的数智化解决方案</p> -->
    </div>
    <div class="center-box">
      <div class="title">尤拉夫科技简介</div>
      <div class="con">
        <p> 杭州尤拉夫科技有限公司是一家11年深耕于政府信息化建设的国家高新技术企业，现拥有50余项软件著作权。公司背靠浙江大学精深的科研
          实力，组建了以“国千”带队的科研团队，拥有机械、水利、化学、材料、计算机等5大学科的科研力量，为公安、应急、消防、综治等政府机构提
          供先进数智化产品、高质量高效率服务及完整解决方案。</p>
        <p> 近年来，公司围绕智能物联网、互联网+等关键方向持续创新，将“万物互联”覆盖社区、园区、工业等诸多场景中，逐渐成为智慧城市建设细
          分领域的新锐企业。</p>
      </div>
      <div class="other">
        <div class="other-item">
          <p class="t-text">10<span class="sub">年+</span></p>
          <p>深耕于政府信息化建设</p>
        </div>
        <div class="line"> </div>
        <div class="other-item">
          <p class="t-text">70<span class="sub">项+</span></p>
          <p>已取得的软件著作权</p>
        </div>
        <div class="line"> </div>
        <div class="other-item">
          <p class="t-text">5<span class="sub">科研力量</span></p>
          <p>背靠浙大精深的科研实力</p>
        </div>
      </div>
    </div>
    <div class="gap" id="sy">
      <div class="gap-box">
        <div class="title animated bounceInRight">使命、愿景、价值观</div>
        <div class="content">
          <div class="c-item">
            <p class="c-item-name ">企业使命</p>
            <p class="line"></p>
            <p>构建安全、便捷、健康的生活圈
            </p>
          </div>
          <div class="c-item">
            <p class="c-item-name ">企业愿景</p>
            <p class="line"></p>
            <p>人人吃得下、睡得着、笑得出来
            </p>
          </div>
          <div class="c-item">
            <p class="c-item-name ">企业价值观</p>
            <p class="line"></p>
            <p>利国为民、严谨高效
            </p>
          </div>
        </div>
      </div>
    </div>
    <img class="center-img" src="../assets/abouts/center-banner.png" mode="" />
    <div class="book" id="ry">
      <div class="title">企业荣誉证书</div>
      <div class="sub">企业荣获高新技术企业证书，共获得软件著作权70多项+；其中有公安类，应急管理类，综合应用类</div>
      <div class="booklist">
        <div class="b-item">
          <div class="img">
            <img src="../assets/abouts/ga-book.png" alt="">
          </div>
          <div>公安类软件著作权</div>
          <div class="num">35</div>
        </div>
        <div class="b-item">
          <div class="img">
            <img src="../assets/abouts/yj-book.png" alt="">
          </div>
          <div>应急管理类软件著作权</div>
          <div class="num">15</div>
        </div>
        <div class="b-item">
          <div class="img">
            <img src="../assets/abouts/zh-book.png" alt="">
          </div>
          <div>综合应用类软件著作权</div>
          <div class="num">20</div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴  -->
    <div class="firends">
      <div class="title">尤拉夫合作伙伴</div>
      <div class="row mb65">
        <img class="dx" src="../assets/abouts/dx.png" alt="">
        <img class="lt" src="../assets/abouts/lt.png" alt="">
        <img class="yd" src="../assets/abouts/yd.png" alt="">
        <img class="aly" src="../assets/abouts/aly.png" alt="">
        <img class="txy" src="../assets/abouts/txy.png" alt="">
      </div>
      <div class="row">
        <img class="dh" src="../assets/abouts/dh.png" alt="">
        <img class="hs" src="../assets/abouts/hs.png" alt="">
        <img class="lk" src="../assets/abouts/lk.png" alt="">
        <img class="hk" src="../assets/abouts/hk.png" alt="">
      </div>
    </div>
    <div class="map-box">
      <baidu-map class='bm-view' @ready='init'>
        <bm-marker :position="{ lng: 120.099993, lat: 30.349101 }"></bm-marker>
      </baidu-map>
    </div>
    <div class="link" id="hz">
      <div class="left">
        <div class="title">联系我们</div>
        <div class="text">详细地址：杭州市余杭区金昌路2800号洋帆中心1号楼9楼</div>
        <div class="text">公司座机：0571-89906536（工作日：8:30 - 17:00）</div>
        <div class="text">售后客服：18757958624（7X24小时服务）</div>
        <div class="text">售后客服：366708（浙江公安短号）</div>
        <div class="text">售后QQ：2711882545</div>
      </div>
      <div class="right">
        <div class="title">业务合作</div>
        <!-- <div class="text">联系方式：15967158886（胡总）</div> -->
        <div class="text">邮&emsp;&emsp;箱： service@youlove.com.cn</div>
      </div>
    </div>
    <bottombanner />
  </div>
</template>
<script>
import Bottombanner from "../components/bottombanner.vue";
import Topnavigation from "../components/topnavigation.vue";
export default {
  components: {
    Bottombanner,
    Topnavigation
  },
  data() {
    return {
      map: null
    }
  },

  mounted() {
    let id = this.$route.query.target ? '#' + this.$route.query.target : '#top'
    document.querySelector(id).scrollIntoView({
      behavior: "smooth"
    });
  },
  methods: {
    // 地图初始化
    init({ BMap, map }) {
      this.map = map
      var point = new BMap.Point(120.099993, 30.349101)  //设置城市经纬度坐标
      map.centerAndZoom(point, 17)    //
      map.setCurrentCity('杭州')   // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(false)    //鼠标滚动缩放
    }

  }
}
</script>
<style lang="less" scoped>
.firends {
  width: 1920px;
  height: 1090px;
  background-image: url(../assets/abouts/firends-bg.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding-top: 120px;
  box-sizing: border-box;

  .title {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
    margin-bottom: 220px;
  }

  .row {
    width: 1592px;
    padding: 0 103px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .dx {
    width: 197px;
    height: 55px;
    margin-right: 120px;
  }

  .lt {
    width: 103px;
    height: 57px;
    margin-right: 120px;
  }

  .yd {
    width: 199px;
    height: 62px;
    margin-right: 120px;
  }

  .aly {
    width: 196px;
    height: 44px;
    margin-right: 120px;
  }

  .txy {
    width: 210px;
    height: 57px;
  }

  .dh {
    width: 180px;
    height: 54px;
    margin-right: 137px;
  }

  .hs {
    width: 118px;
    height: 65px;
    margin-right: 105px;
  }

  .lk {
    width: 315px;
    height: 59px;
    margin-right: 125px;
  }

  .hk {
    width: 337px;
    height: 44px;
  }

  .mb65 {
    margin-bottom: 65px;
  }
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  background-image: url(../assets/abouts/banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: 100%;
  font-size: 50px;
  font-family: OPPOSans;
  font-weight: 800;
  color: #FFFFFF;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding-left: 207px;
  box-sizing: border-box;
  justify-content: center;

  p {
    line-height: 80px;
    margin: 0;
  }
}

.center-box {
  background-image: url(../assets/abouts/center.png);
  background-repeat: no-repeat;
  width: 1920px;
  height: 1080px;
  background-size: 100%;
  padding-top: 120px;
  box-sizing: border-box;

  .title {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  .con {
    width: 1157px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 38px;
    text-align: left;
    margin-top: 170px;
    padding-left: 573px;
    position: relative;

    p {
      text-indent: 2em;
      margin: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 502px;
      top: -26px;
      width: 57px;
      height: 42px;
      background-image: url(../assets/abouts/yin.png);
      background-repeat: no-repeat;
      background-size: 100%;
    }
  }

  .other {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 100px;
    padding-right: 270px;

    p {
      margin: 0;
    }

    .other-item {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 38px;
      text-align: left;
    }

    .t-text {
      font-size: 60px;
      font-family: Helvetica;
      font-weight: bold;
      color: #0074F4;
    }

    .sub {
      font-size: 18px;
      font-family: Helvetica;
      margin-left: 20px;
    }

    .line {
      width: 1px;
      height: 84px;
      background: #C4C4C4;
      margin: 0 106px;
    }
  }
}

.gap {
  width: 1920px;
  height: 680px;
  background-color: #fff;
  padding-top: 180px;
  box-sizing: border-box;

  &-box {
    width: 1590px;
    height: 600px;
    background: #FAFAFA;
    margin: 0 auto;
    position: relative;
    z-index: 99;
    padding: 100px 80px 150px 80px;
    box-sizing: border-box;

    .title {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-bottom: 164px;
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }

    .line {
      height: 2px;
      background: #333333;
      margin-top: 20px;
      margin-bottom: 50px;
    }

    p {
      margin: 0;
    }

    .c-item {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .c-item-name {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
  }
}

.center-img {
  width: 1920px;
  height: 400px;
}

.book {
  width: 1920px;
  background-color: #f8f8f8;
  padding: 120px 230px 172px 216px;
  box-sizing: border-box;

  .title {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 40px;
  }

  .sub {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 180px;
  }

  .booklist {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .b-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  img {
    width: 444px;
    height: 353px;
    margin-bottom: 48px;
  }

  .num {
    font-size: 60px;
    font-family: Helvetica;
    font-weight: bold;
    color: #333333;
    margin-top: 30px;
  }
}

.map-box {
  padding-top: 120px;
  width: 1920px;
  box-sizing: border-box;

  .bm-view {
    width: 1592px;
    height: 450px;
    margin: 0 auto;
  }
}

.link {
  width: 1920px;
  display: flex;
  justify-content: center;
  text-align: left;
  margin-top: 110px;
  padding-bottom: 102px;

  .title {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-bottom: 60px;
  }

  .text {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    line-height: 46px;
  }

  .left {
    margin-right: 390px;
  }
}
</style>
