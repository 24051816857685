<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:41
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2023-01-31 14:47:34
 * @FilePath: \ulove-web\src\views\About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="park">
    <topnavigation />
    <!-- banner -->
    <div class="banner">
    </div>
    <!-- 文字介绍 -->
    <div class="js" >
      <div @click="videoplay" v-if="!flay"  class="play">
        <img src="../assets/park/play.png" alt="">
      </div>
      <div  class="text" :class="flay?'text-play':''">
        <div class="title">智慧园区解决方案</div>
        <p> 智慧园区解决方案，为办公楼宇、企业园区提供定制化智慧管理系统，软硬件深度融合，运用物联网、
          人工智能、大数据分析等技术，满足智慧通行、智慧安防、智慧停车、智慧迎宾等多样化场景需求，实现
          对外服务形象提升；可视化数字驾驶舱让管理端一屏触达用户信息，分析人员风险，疫情精准防控，实现
          运营降本增效，从而构建新一代安全、高效、绿色的智慧园区。</p>
      </div>
      <div >
      <video  v-if="flay" :controls="true"  :autoplay="true" :loop="true" :playsinline="true" class="video" ref="video">
        <source src="../assets/park/vd.mp4" type="video/mp4" />
      </video>
    </div>
    </div>
    <!-- 案例 -->
    <div class="case">
      <div class="title" style="margin-bottom:40px;">
        <img src="../assets/hyy/twobule1.png" />
        合作案例
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <div class="text">
        <span>为赣州市公安局开发区分局打造了“企业服务助手”，通过向企业、社区赋码，由员工、住户自主扫码录入个人信息，同时接入智慧安防小区门禁、道闸，联通停车棚、充电桩等智能管理系统，共采集数据6万余条，为公安机关实现精准防控提供数据支撑。</span>
        <span>依托数据智能分析，公安机关破获各类案件15起，成功打掉一个以盗窃为目标，冒用他人身份信息入职企业实施盗窃的犯罪团伙，为企业挽回经济损失80余万元。</span>
        <span>该项目在所在省省公安基层技术革新评比中夺得二等奖。</span>
      </div>

    </div>

    <div class="center-banner"></div>
    <!-- 方案特点 -->
    <div class="characteristic">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        方案特点
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <div class="main">
        <div class="row">
          <div class="item" :class="activeIndex == 0 ? 'active' : ''" @click="changeActive(0)">
            <div class="no">01</div>
            <div class="item-main">
              <div class="no-title">兼容并蓄</div>
              <div class="no-con">园区统一支撑平台，满足园区未来五年在业务生长中
                所需要的发展空间。</div>
            </div>
          </div>
          <div class="item" :class="activeIndex == 1 ? 'active' : ''" @click="changeActive(1)">
            <div class="no">02</div>
            <div class="item-main">
              <div class="no-title">善治善能</div>
              <div class="no-con">将园区经营所涉及的人员、资产、资源、项目等诸多要素进行功能整合，能够智能检测园区环境中潜在的安全威胁，实时上报报警。</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="item" :class="activeIndex == 2 ? 'active' : ''" @click="changeActive(2)">
            <div class="no">03</div>
            <div class="item-main">
              <div class="no-title">互联互通</div>
              <div class="no-con">园区设施设备的互联互通和统一运维，保障运维的可控性和经济性。包括门禁、安防、停车等。</div>
            </div>
          </div>
          <div class="item" :class="activeIndex == 3 ? 'active' : ''" @click="changeActive(3)">
            <div class="no">04</div>
            <div class="item-main">
              <div class="no-title">数字孪生</div>
              <div class="no-con">园区可视化驾驶舱，实现线上线下协同，满足立体指
                挥和参观宣传需要。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="core">
      <div class="title">
        <img src="../assets/hyy/twobule1.png" />
        核心功能
        <img class="img-r" src="../assets/hyy/twobule1.png" />
      </div>
      <div class="row">
        <div class="item">
          <div class="item-title">
            <span class="no">01</span>
            <span>总览</span>
          </div>
          <div class="item-text">
            <div>· 园区概况 · 系统概况</div>
            <div>· 安防概况 · 通知中心</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">02</span>
            <span>安防</span>
          </div>
          <div class="item-text">
            <div>· 安防警告 · 视频监控</div>
            <div>· 人车轨迹 · 应急疏散</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">03</span>
            <span>消防</span>
          </div>
          <div class="item-text">
            <div>· 消防概况 · 消防警告</div>
            <div>· 消防设备 · 消防预案</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">04</span>
            <span>疫情</span>
          </div>
          <div class="item-text">
            <div>· 疫情统览 · 体温异常</div>
            <div>· 统计趋势 · 告警记录</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">05</span>
            <span>能耗</span>
          </div>
          <div class="item-text">
            <div>· 能耗警告 · 实时监控</div>
            <div>· 负荷统计 · 能耗排名</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item">
          <div class="item-title">
            <span class="no">06</span>
            <span>环境</span>
          </div>
          <div class="item-text">
            <div>· 环境告警 · 当年建议</div>
            <div>· 环境统计 · 环境设备</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">07</span>
            <span>设备</span>
          </div>
          <div class="item-text">
            <div>· 设备告警 · 设备信息</div>
            <div>· 设备统计</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">08</span>
            <span>人员</span>
          </div>
          <div class="item-text">
            <div>· 人员概览 · 入园统计</div>
            <div>· 访客预约</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">09</span>
            <span>车辆</span>
          </div>
          <div class="item-text">
            <div>· 停车概览 · 入园车辆</div>
            <div>· 通行记录</div>
          </div>
        </div>
        <div class="item">
          <div class="item-title">
            <span class="no">10</span>
            <span>运营</span>
          </div>
          <div class="item-text">
            <div>· 电梯管理 · 通行管理</div>
            <div>· 工单管理</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频 -->
    <!-- <div>
      <video :muted="true" :autoplay="true" :loop="true" :playsinline="true" class="video" ref="video">
        <source src="../assets/park/vd.mp4" type="video/mp4" />
      </video>
    </div> -->

    <bottombanner />
  </div>
</template>
<script>
import Bottombanner from "../components/bottombanner.vue";
import Topnavigation from "../components/topnavigation.vue";
export default {
  components: {
    Bottombanner,
    Topnavigation
  },
  data() {
    return {
      flay:false,
      activeIndex: 0
    }
  },

  mounted() {

  },
  methods: {
    changeActive(e) {
      if (e != this.activeIndex) {
        this.activeIndex = e
      }
    },
    videoplay(){
      this.flay=true
    }

  }
}
</script>
<style lang="less" scoped>
.park {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-r {
    transform: rotate(180deg);
  }
}

.banner {
  background-image: url(../assets/park/banner.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 1080px;
  background-size: cover;
  font-size: 50px;
  font-family: OPPOSans;
  font-weight: 800;
  color: #FFFFFF;
  display: flex;
  text-align: left;
  flex-direction: column;
  padding-left: 207px;
  box-sizing: border-box;
  justify-content: center;
}

.js {
  width: 1417px;
  height: 685px;
  background: url(../assets/park/bg1.png) 100% no-repeat;
  margin-top: 110px;
  position: relative;
  .video {
    width: 1417px;
    height: 685px;
    object-fit: contain;
    margin-bottom: 0;
    line-height: 0;
    background-color: #000;
    border-radius: 5%;
  }
  .play{
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%; top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .text {
    width: 1220px;
    height: 312px;
    background: url(../assets/park/bg2.png) 100% no-repeat;
    position: absolute;
    top: 549px;
    left: 99px;
    padding: 54px 48px 0 69px;
    box-sizing: border-box;
  }
  .text-play{
    position: absolute;
    top: 720px;
    left: 99px;
  }
  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #222222;
    text-align: left;
  }

  p {
    font-size: 24px;
    text-align: left;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    text-indent: 2em;
  }
}

.case {
  width: 1601px;
  height: 326px;
  background: url(../assets/park/bg3.png) 100% no-repeat;
  margin-top: 382px;
  padding: 50px 62px 0 62px;
  box-sizing: border-box;
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;


  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  img {
    width: 38px;
    height: 36px;
    margin: 0 30px;
  }



  .text {
    line-height: 0;
  }

  span {
    width: 100%;
    display: inline-block;
    text-indent: 2em;
    text-align: left;
    line-height: 30px;
  }
}

.center-banner {
  width: 1600px;
  height: 460px;
  background: url(../assets/park/bg4.png) 100% no-repeat;
  margin-top: 75px;
}

.characteristic {
  width: 100%;
  height: 1080px;
  background: url(../assets/park/bg5.png) no-repeat;
  background-size: cover;
  margin-top: 120px;

  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    padding-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin: 0 30px;
    }
  }

  .main {
    margin-top: 90px;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .row:first-child {
    margin-bottom: 50px;
  }

  .item {
    width: 770px;
    height: 273px;
    background: #FFFFFF;
    border: 1px solid #0074F4;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    padding: 0 46px 0 50px;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
  }

  .item:last-child {
    margin-left: 60px;
  }

  .item-main {
    text-align: left;
  }

  .no {
    font-size: 80px;
    font-family: Helvetica;
    font-weight: bold;
    margin-right: 40px;
  }

  .no-title {
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .no-con {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;

    line-height: 30px;
  }

  .item:hover,
  .active {
    width: 770px;
    height: 273px;
    background: url(../assets/park/bg6.png) 100% no-repeat;
    color: #fff;
  }
}

.core {
  width: 100%;
  height: 1080px;
  background: url(../assets/park/bg7.png) no-repeat;
  background-size: cover;

  .title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    padding-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;

    img {
      margin: 0 30px;
    }
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .row:last-child {
    margin-bottom: 0;
  }

  .item {
    width: 300px;
    height: 250px;
    background: url(../assets/park/bg8.png) no-repeat;
    background-size: cover;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .item:last-child {
    margin-right: 0;
  }

  .item-title {
    font-size: 32px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #333333;
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }

  .no {
    font-size: 50px;
    font-family: Helvetica;
    font-weight: bold;
    color: #333333;
    margin-right: 20px;
  }

  .item-text {
    text-align: left;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 44px;
  }
}


</style>
