<!--
 * @Author: flf flf@youlove.com.cn
 * @Date: 2022-12-27 09:47:41
 * @LastEditors: flf flf@youlove.com.cn
 * @LastEditTime: 2022-12-27 14:00:18
 * @FilePath: \ulove-web\src\views\hardware\yj.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="yj">
    <topnavigation class="header"></topnavigation>
    <router-view></router-view>
    <bottombanner></bottombanner>
  </div>
</template>

<script>
// import Access from "./Access.vue";
import topnavigation from '../../components/topnavigation.vue';
import bottombanner from "../../components/bottombanner.vue";
// import Ladder from "./Ladder .vue";
export default {
  components: { topnavigation, bottombanner },

  data() {
    return {};
  },

  mounted() { },

  methods: {},
};
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.yj {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
