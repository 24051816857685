var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"community"},[_c('topnavigation'),_c('div',{staticClass:"banner"}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('bottombanner')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"programme"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../assets/hyy/twobule1.png")}}),_vm._v(" 智慧社区解决方案 "),_c('img',{staticClass:"img-r",attrs:{"src":require("../assets/hyy/twobule1.png")}})]),_c('p',[_vm._v(" 好用点点智慧社区解决方案,面向各类型社区提供与公安内网打通的Saas平台、智慧硬件产品,从治安防控升级、社区管理高效、居住 体验优化三个维度出发,以一标三实信息采集为基础,融合智慧门禁、智慧道闸、智慧充电桩、智慧梯控等系统,搭建数字看板、数字驾驶 舱,自动分析辖区风险并提供精准的人口管理、流动人员管理、疫情管控等创新应用,政府、职能单位与小区物业可联动共治,全面提高社 区智治水平。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"case"},[_c('div',{staticClass:"title"},[_vm._v("合作案例")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/sq/center1.png"),"alt":""}}),_c('p',[_vm._v("杭州滨江同协金座小区")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("../assets/sq/center2.png"),"alt":""}}),_c('p',[_vm._v("赣州锦绣新天地")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wg"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../assets/hyy/twobule1.png")}}),_vm._v(" 智慧社区 — 网格化管理 "),_c('img',{staticClass:"img-r",attrs:{"src":require("../assets/hyy/twobule1.png")}})]),_c('div',{staticClass:"sub-title"},[_vm._v("通过一定标准将社区划分为多个网格，在线上对网格内的\"人、地、物、情、事、组织”信息统一管理。")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"item"},[_vm._v("实有房屋管理")]),_c('div',{staticClass:"item"},[_vm._v("户籍管理")]),_c('div',{staticClass:"item"},[_vm._v("人口卡片")]),_c('div',{staticClass:"item"},[_vm._v("小区/村管理")]),_c('div',{staticClass:"item"},[_vm._v("网格看板")])]),_c('img',{staticClass:"wg-main",attrs:{"src":require("../assets/sq/wg-main.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fw"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../assets/hyy/twobule1.png")}}),_vm._v(" 一站式服务百姓民生 "),_c('img',{staticClass:"img-r",attrs:{"src":require("../assets/hyy/twobule1.png")}})]),_c('img',{staticClass:"wg-main",attrs:{"src":require("../assets/sq/fw-main.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"yj"},[_c('div',{staticClass:"title"},[_c('img',{attrs:{"src":require("../assets/hyy/twobule1.png")}}),_vm._v(" 集成智能硬件，流口轨迹全掌控、数据信息全共享 "),_c('img',{staticClass:"img-r",attrs:{"src":require("../assets/hyy/twobule1.png")}})]),_c('img',{staticClass:"wg-main",attrs:{"src":require("../assets/sq/yj-main.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"other"},[_c('img',{attrs:{"src":require("../assets/sq/other-main.png"),"alt":""}})])
}]

export { render, staticRenderFns }